import "./face.css"
import { IFace, IFaceImage, IFaceText } from "../../../../Models/IBadge";
import FaceText from "./face-text";
import FaceImage from "./face-image";
import { instanceOfIFaceText } from "../../../../utils";

export default function Face(props: {landscape: boolean, lanyard: boolean, face: IFace, scollable: boolean}) {
    return (
        <div id={props.face.id} className="face" style={{touchAction: props.scollable ? 'auto' : 'none'}}>
            <div className={props.landscape ? 'landscape' : 'portrait'} style={{position: 'relative'}}>
                {props.lanyard ? <div className="lanyard-hole" data-html2canvas-ignore></div> : null }

                <div className="face-image" style={{
                    backgroundImage: props.face.imageUrl ? `url(${props.face.imageUrl})` : '',
                    backgroundColor: props.face.color || '',
                    opacity: props.face.opacity ? props.face.opacity / 100 : 'unset'
                }}></div>

                {props.face.objects.map((obj, i) => (
                    <>
                    {instanceOfIFaceText(obj)
                        ? 
                            <FaceText 
                                key={i}
                                faceId={props.face.id} 
                                value={obj as IFaceText} 
                            />
                        :
                        <FaceImage 
                            key={i}
                            faceId={props.face.id} 
                            value={obj as IFaceImage}
                        />
                    }
                    </>
                ))}
            </div>
        </div>
       
    )
}
