import { Input } from "@nextui-org/react";
import { IPosition } from "../../../../Models/IBadge";
import { highlightValue } from "../../../../utils";

type PositionChanged = (position: IPosition) => void;
export default function PositionMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IPosition,
    onValueChanged: PositionChanged
}) {
    const setX = (value: string) => {
        let parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
            parsedValue = 0;
        }

        props.onValueChanged({...props.value, xPercentage: parsedValue});
    }

    const setY = (value: string) => {
        let parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
            parsedValue = 0;
        }

        props.onValueChanged({...props.value, yPercentage: parsedValue});
    }

    return (
       <div>
            <div style={{display: 'flex', gap: '15px'}}>
                <div>
                    <p className="text-tiny text-default-500 mb-1">X</p>
                    <Input 
                        color={props.color}
                        size={props.size}
                        variant="bordered"
                        type="number" 
                        value={props.value.xPercentage.toString()} 
                        onValueChange={setX} 
                        endContent={<div>%</div>} 
                        onFocus={highlightValue}
                    />
                </div>

                <div>
                    <p className="text-tiny text-default-500 mb-1">Y</p>
                    <Input 
                        color={props.color}
                        size={props.size}
                        variant="bordered"
                        type="number"
                        value={props.value.yPercentage.toString()} 
                        onValueChange={setY} 
                        endContent={<div>%</div>} 
                        onFocus={highlightValue}
                    /> 
                </div>
            </div>
       </div>
    )
}