import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css'
import Build from "./Pages/Builder/Build"
import CheckoutSuccess from "./Pages/CheckoutSuccess"
import { NextUIProvider } from '@nextui-org/system';
import Nav from './Components/nav';
import PrivacyPolicy from './Pages/Privacy';
import About from './Pages/About';
import { EntertainmentBadges, ProfessionalBadges } from './Pages/Badges';
import Contact from './Pages/Contact';

const router = createBrowserRouter([
    {
        path: '*',
        Component: About
    },
    {
      path: "build",
      Component: Build,
    },
    {
        path: 'success',
        Component: CheckoutSuccess
    },
    {
        path: 'privacy',
        Component: PrivacyPolicy
    },
    {
        path: 'professional',
        Component: ProfessionalBadges,
    },
    {
        path: 'entertainment',
        Component: EntertainmentBadges
    },
    {
        path: 'contact',
        Component: Contact
    }
]);

export default function App() {
    return (
        <NextUIProvider>
            <div>
                <Nav />
                <div id="container">
                    <RouterProvider router={router} />
                </div>
            </div>
        </NextUIProvider>
    )
}
