import { faEnvelope, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Link, Spacer, Textarea } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { isValidEmail } from "../utils";

export default function Contact() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [received, setReceived] = useState(false);

    const MaxMessageLength = 500;

    useEffect(() => {
        setValid(
            isValidEmail(email)
            && message.length > 3 && message.length <= MaxMessageLength
        )
    }, [email, message])

    const submit = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, message})
        })
        .then(res => {
            if(res.ok) {
                setReceived(res.ok)
            }
        })
        .finally(() => setLoading(false))
    }

    if(received) return (
        <div style={{margin: 'auto', textAlign: 'center',  padding: '20px'}}>
            <div>
                <div className="text-2xl">Thank your for your feedback!</div>
                <div>We will be in contact soon.</div>
                <Spacer y={5} />
                <Button as={Link} href="/" startContent={<FontAwesomeIcon icon={faHome}/>}>Go Back Home</Button>
            </div> 
        </div>
    )

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
            <div className="text-3xl ">
                Contact Us
            </div>

            <div>
                Enter your email and describe how we can help
            </div>

            <Spacer y={5} />

            <div style={{display: 'grid', gridTemplateColumns: 'auto', gap: '15px'}} className="lg:w-[500px] w-full" >
                <Input
                    type="email"
                    label="Email"
                    startContent={<FontAwesomeIcon icon={faEnvelope}/>}
                    value={email}
                    onValueChange={setEmail}
                />

                <Textarea
                    value={message}
                    onValueChange={setMessage}
                    placeholder="Tell us how we can help"
                    description={`${message.length} / ${MaxMessageLength}`}
                />

                <Button color="primary" isDisabled={!valid} isLoading={loading} onClick={submit}>Submit</Button>
            </div>

            <Spacer y={5} />

            <div className="text-sm">
                Or email us direclty at <span className="font-bold">support@build-a-badge.com</span>
            </div>
        </div>
    )
}