import { createContext } from "react";
import { IBadgeObject } from "../Models/IBadge";

type EditSetter = (editContext: IBadgeObject | null) => void;

export const EditContext = createContext<{
    editContext: IBadgeObject | null,
    setEditContext: EditSetter,
    onDelete: any,
    onCopy: any
}>({
    editContext: null,
    setEditContext: () => {},
    onDelete: () => {},
    onCopy: () => {}
});