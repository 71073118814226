import { CSSProperties, useEffect, useState } from "react";
import { IPadding } from "../../../../../Models/IBadge";

export default function usePaddingStyles(padding: IPadding) {

    const calculate = (): CSSProperties => {
        return {
            padding: `${padding.verticalPadding || 0}px ${padding.horizontalPadding || 0}px ${padding.verticalPadding || 0}px ${padding.horizontalPadding || 0}px`,
        }
    }

    const [styles, setStyles] = useState<CSSProperties>(calculate());

    useEffect(() => {
        setStyles(calculate())
    }, [padding]);

    return styles;
}