import { faEnvelope, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalContent, ModalHeader, ModalBody, Spacer, ModalFooter, Button, Card, Input, Link } from "@nextui-org/react";
import { useContext, useState, useEffect } from "react";
import { BadgeContext } from "../../../../Context/badge-context";
import Face from "../face/face";
import { getFaceImages } from "../../../../utils";

export default function Payment(props: {
    email: string,
    setEmail: any,
    isValidEmail: boolean,
    verifiedEmail: boolean,
    onVerifyEmail: any,
    onCancel: any
}) {
    const {badge} = useContext(BadgeContext);
    const [quantity, setQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (quantity > 50) setQuantity(50);
    }, [quantity])

    useEffect(() => {
        setIsValid(props.isValidEmail && quantity >= 1 && quantity <= 50)
    }, [props.isValidEmail, quantity])

    

    const startCheckout = async () => {
        try {
            setIsLoading(true);

            var images = await getFaceImages("checkout-container");

            const formData = new FormData();
            formData.append("customerEmail", props.email);
            formData.append("quantity", quantity.toString());
            formData.append("badge", JSON.stringify({
                name: badge.name,
                landscape: badge.landscape,
                lanyard: badge.lanyard
            }));
            formData.append("front", images[0]);
            formData.append("back", images[1]);

            var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/start-checkout`, {
                method: 'POST',
                body: formData
            });

            if(!res.ok) throw new Error();

            var resBody = await res.json();

            window.location = resBody.url;
        } catch {
            // todo
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <ModalContent>
            <ModalHeader>Checkout</ModalHeader>
            <ModalBody>
                <div id="checkout-form">
                    <Input 
                        label="Email" 
                        description={!props.verifiedEmail ?
                            <div>
                                First time? <Link size="sm" onClick={props.onVerifyEmail}>Verify email</Link> for a <span className="font-bold">First Badge Discount</span>
                            </div>
                            :
                            "Your email address has been verified"
                        }
                        startContent={<FontAwesomeIcon icon={faEnvelope} />}
                        type="email" 
                        value={props.email} 
                        onValueChange={props.setEmail} 
                        isRequired
                    />
                    <Input 
                        label="Quantity"
                        description={
                            <div>Ordering multiple may qualify for a <span className="font-bold">Bulk Discount</span></div>
                        }
                        type="number"
                        value={quantity.toString()} 
                        onValueChange={v => setQuantity(parseInt(v))} 
                        isRequired
                        errorMessage="Invalid quantity"
                    />
                </div>

                <Spacer y={5}/>

                <BadgePreviewer />  

                <Spacer y={5}/>

                {/* <blockquote className="text-center text-tiny border px-4 py-3 rounded-xl [&>p]:m-0 border-warning-100 bg-warning-50/20 my-2">
                    Build A Badge reserves the right to deny badges that resemble official government identification, contain illicit content, or for any other reason we deem. 
                </blockquote>     */}

                <div className="text-center">
                    <Link size="sm" href="privacy" isBlock showAnchorIcon target="_">Privacy Policy</Link>    
                </div>
            </ModalBody>

            <ModalFooter>
                <Button onClick={props.onCancel} color="danger">Cancel</Button>

                <Button 
                    endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                    color="primary"
                    onClick={startCheckout}
                    isLoading={isLoading}
                    isDisabled={!isValid}
                    
                >
                    Shipping & Payment
                </Button>
            </ModalFooter>
        </ModalContent>
    )
}

function BadgePreviewer() {
    const {badge} = useContext(BadgeContext);

    return (
        <div id="checkout-container" style={{position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', gap: '20px'}}>
            <Card>
                <Face landscape={badge.landscape} lanyard={badge.lanyard} face={badge.front} scollable={true}/>
            </Card>
            <Card>
                <Face landscape={badge.landscape} lanyard={badge.lanyard} face={badge.back} scollable={true}/>
            </Card>
        </div>
    )
}