import { Card, CardFooter, Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react"
import { getFaceImages } from "../../../utils";
import { BadgeContext } from "../../../Context/badge-context";
import html2canvas from "html2canvas";
import { EditContext } from "../../../Context/edit-context";


export default function Preview() {
    const [imgSrc, setImgSrc] = useState('');
    const [previewSrc, setPreviewSrc] = useState('');
    const {badge} = useContext(BadgeContext);
    const {editContext} = useContext(EditContext);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [width, setWidth] = useState(19);
    const [timeout, setStateTimeout] = useState<any>(null);

    const update = () => {
        getFaceImages("badge-editor")
            .then(faces => URL.createObjectURL(faces[0]))
            .then(setImgSrc)
            .catch(() => {});

        setWidth(badge.lanyard ? 22 : 19)
    }

    useEffect(() => {
        if(isOpen) {
            html2canvas(document.getElementById('preview-container')!, {
                scale: 5
            })
                .then(canvas => setPreviewSrc(canvas.toDataURL()))
        }
    }, [isOpen])

    useEffect(() => {
        if(timeout != null) clearTimeout(timeout);
        setStateTimeout(setTimeout(update, 1000));
    }, [badge, editContext])

    return (
        <div style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <div>
                <Card isPressable onClick={onOpen} isFooterBlurred>                   
                    <div id="preview-container" style={{width: '300px', backgroundColor: 'white', position: 'relative', zIndex: 2, transform: 'translate(0px, -90px) scale(2) '}}>
                        <img src={imgSrc}
                            style={{
                                zIndex: -1,
                                position: 'absolute',
                                left: badge.lanyard ? '48.6%' : '48.8%',
                                top: badge.lanyard ? '61.1%' : '62%',
                                width: `${width}%`,
                                border: 'solid .5px whitesmoke',
                                borderRadius: '5px',
                                padding: badge.lanyard ? '' : '2px',
                                backgroundColor: 'whitesmoke',
                                transform: 'translate(-50%, -50%) rotate(1.3deg)',
                                backgroundSize: `${width}% ${width * 1.1}%`,}} />
                        <img src="/images/preview-background-transparent.png" />
                    </div>

                    <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 overflow-hidden py-2 absolute before:rounded-xl rounded-large bottom-1 shadow-small m-1 w-[calc(100%_-_8px)] z-10">
                        <p className="text-md text-white/80">Preview (Beta)</p>
                    </CardFooter>
                </Card>
            </div>

            <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" backdrop="blur" classNames={{wrapper: 'preview-modall', backdrop: 'preview-modall'}}>
                <ModalContent>
                    <ModalHeader>Preview (Beta)</ModalHeader>
                    <ModalBody>
                        <img src={previewSrc} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}