import { IFaceImage } from "../../../../Models/IBadge";
import { useContext, useEffect, useState } from "react";
import { EditContext } from "../../../../Context/edit-context";
import { OptionsContext } from "../../../../Context/options-context";
import ResizerArrows from "./resizer-arrows";
import CurrentPositionWindow from "./current-position-window";
import useBorderStyles from "./hooks/border-hook";
import usePositionStyles from "./hooks/position-hook";
import useSizeStyles from "./hooks/size-hook";
import usePaddingStyles from "./hooks/padding-hook";
import RotateBox from "./rotate-box";

export default function FaceImage(props: {
    faceId: string,
    value: IFaceImage
}) {
    const [selected, setSelected] = useState(false);
    const {editContext, setEditContext} = useContext(EditContext);
    const {options} = useContext(OptionsContext);

    const [id, setId] = useState(`${props.faceId}-${props.value.id}`)

    useEffect(() => {
        setId(`${props.faceId}-${props.value.id}`)
    }, [props.faceId, props.value.id])

    useEffect(() => {
        setSelected(editContext != null && editContext?.id === props.value.id)
    }, [editContext])
    
    const clicked = (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(options.locked) return;

        e.stopPropagation();
        setEditContext(props.value);
    }

    const positionStyles = usePositionStyles(props.value);
    const borderStyles = useBorderStyles(selected, props.value);
    const paddingStyles = usePaddingStyles(props.value);
    const sizeStyles = useSizeStyles(props.value);

    return (
        <div 
            id={id}
            style={{
                ...positionStyles,
                ...borderStyles,
                ...paddingStyles,
                ...sizeStyles,
                borderRadius: props.value.avatar ? '50%' : borderStyles.borderRadius,
                opacity: props.value.opacity! / 100,
            }}
            onClick={clicked}  
        >
            <img src={props.value.url} style={{
                borderRadius: props.value.avatar ? '50%' :  borderStyles.borderRadius,
                width: '100%', 
                height: '100%',
                minWidth: '5px'
            }} /> 

            <RotateBox visible={selected} />
            {selected && <ResizerArrows /> }
            {selected && <CurrentPositionWindow id={id}/>}
        </div>
    )
}