import { CSSProperties, useEffect, useState } from "react";
import { ISize } from "../../../../../Models/IBadge";

export default function useSizeStyles(size: ISize) {
    const calculate = (): CSSProperties => {
        return {
            width: size.width ? `${size.width}px` : undefined,
            height: size.height ? `${size.height}px` : undefined,     
        }
    }

    const [styles, setStyles] = useState<CSSProperties>(calculate());

    useEffect(() => {
        setStyles(calculate())
    }, [size]);

    return styles;
}