import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faFont, faTrash, faMountainSun, faEraser, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverTrigger, Button, PopoverContent, Spacer, useDisclosure, Modal, ModalContent, ModalBody, ModalHeader, ModalFooter, Input, Tooltip } from "@nextui-org/react";
import { useContext, useState } from "react";
import { FaceContext } from "../../../../Context/face-context";
import { cast, highlightValue, instanceOfIPosition, loadImage, uuidv4 } from "../../../../utils";
import ColorPicker from "../menu/color-picker";
import { IFaceImage, IFaceText } from "../../../../Models/IBadge";
import Undo from "../undo";

export default function FaceControls() {
    const {face, setFace} = useContext(FaceContext);    

    const calculateZindex = () => {
        let max = 0;
        for(var object of face.objects) {
            if(instanceOfIPosition(object)) max = Math.max(max, object.zIndex)
        }
        return max;
    }

    const addImage = () => {
        loadImage((url: string) => {
            face.objects.push(cast<IFaceImage>(
                {
                    id: uuidv4(),
                    url,
                    xPercentage: 50,
                    yPercentage: 50,
                    width: 200,
                    height: null,
                    zIndex: calculateZindex(),
                    borderWidth: 0
                }
            ))
            setFace({...face});
        })   
    }

    const addText = () => {
        face.objects.push(cast<IFaceText>(
            {
                id: uuidv4(),
                content: 'Text',
                xPercentage: 50,
                yPercentage: 50,
                textAlign: 'center',
                zIndex: calculateZindex(),
                width: 100,
                height: null,
                borderWidth: 0
            }
        ))
        setFace({...face});
    }

    const reset = () => {
        setFace({id: uuidv4(), objects: []})
    }

    return (
        <div style={{display: 'flex', gap: '20px'}}>
            <Background />

            <Tooltip content="Add Image" color="primary">
                <Button 
                    startContent={<FontAwesomeIcon icon={faImage}/>}
                    onClick={addImage}
                    color="primary"
                    isIconOnly
                />
            </Tooltip>

            <Tooltip content="Add Text" color="secondary">
                <Button 
                    startContent={<FontAwesomeIcon icon={faFont}/>}
                    onClick={addText}
                    color="secondary"
                    isIconOnly
                />
            </Tooltip>

            <Undo />

            <ClearFace onReset={reset} />            
        </div>
    )
}

function Background() {
    const {face, setFace} = useContext(FaceContext);
    const [isOpen, setIsOpen] = useState(false);

    const setImage = () => {
        loadImage((imageUrl: string) => {
            setFace({
                ...face,
                imageUrl
            })
        })
    };

    const setColor = (color: string) => {
        setFace({
            ...face,
            color
        })
    }

    const setOpacity = (opacity: number) => {
        if(opacity < 1 || isNaN(opacity)) opacity = 1;
        else if (opacity > 100) opacity = 100;

        setFace({
            ...face,
            opacity
        })
    }

    const resetBackground = () => {
        setFace({
            ...face,
            imageUrl: null,
            color: null
        })
    }

    const button =  <Button isIconOnly color="default" onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faMountainSun}/></Button>

    if(!isOpen) return (
        <Tooltip content="Change Background">
            {button}
        </Tooltip>
    )

    return (
        <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger>
                {button}
            </PopoverTrigger>
            <PopoverContent>
                <div className="text-tiny text-default-500 text-start">Background</div>
                <Spacer y={1} />
                <div style={{display: 'flex', gap: '5px'}}>
                    <Button onClick={setImage} isIconOnly startContent={<FontAwesomeIcon icon={faImage}/>} />
                    <ColorPicker value={face.color} onValueChange={setColor} />
                    <Input
                        type="number"
                        value={(face.opacity || 100).toString()}
                        startContent={<FontAwesomeIcon icon={faEye} />}
                        onValueChange={v => setOpacity(parseFloat(v))}
                        onFocus={highlightValue}
                    />
                    <Button 
                        onClick={resetBackground} 
                        isIconOnly 
                        startContent={<FontAwesomeIcon icon={faTrash}/>} 
                        color="danger"
                    />
                    
                </div>
            </PopoverContent>
        </Popover>
    )
}

function ClearFace(props: {onReset: any}) {
    const {isOpen, onOpen, onClose} = useDisclosure();

    const reset = () => {
        props.onReset();
        onClose();
    }

    return (
        <>

            <Tooltip content="Erase" color="danger">
                <Button 
                    startContent={<FontAwesomeIcon icon={faEraser}/>}
                    color="danger"
                    onClick={onOpen}
                    isIconOnly
                />
            </Tooltip>
            

            <Modal isOpen={isOpen} onClose={onClose} backdrop="blur" placement="center">
                <ModalContent>
                    <ModalHeader>Erase</ModalHeader>
                    <ModalBody>
                        <span>Are you sure you want to erase this side?</span>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} color="primary">No</Button>
                        <Button onClick={reset} color="danger" endContent={<FontAwesomeIcon icon={faEraser}/>}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}