import { faCircleUser, faEye, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, Input, Tooltip } from "@nextui-org/react";
import { IFaceImage } from "../../../../Models/IBadge";
import { highlightValue, loadImage } from "../../../../utils";
import BorderMenu from "./border";
import PositionMenu from "./position";
import SizeMenu from "./size";
import LayerMenu from "./layer";
import PaddingMenu from "./padding";
import RotationMenu from "./rotation";

type FaceImageChanged = (faceImage: IFaceImage) => void;
export default function FaceImageMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IFaceImage,
    onValueChanged: FaceImageChanged
}) {

    const replaceImage = () => {
        loadImage((url: string) => {
            props.onValueChanged({...props.value, url})
        })   
    }

    const toggleAvatar = () => props.onValueChanged({...props.value, avatar: !props.value.avatar})
    const setOpacity = (opacity: number) => {
        if(opacity < 1 || isNaN(opacity)) opacity = 1;
        else if (opacity > 100) opacity = 100;

        props.onValueChanged({
            ...props.value,
            opacity
        })
    }

    return (
        <div style={{display: 'grid', gap: '25px'}}>
            <div style={{display: 'flex', gap: '15px'}}>
                <Tooltip content="Change Image">
                    <Button size={props.size} color={props.color} isIconOnly onClick={replaceImage}><FontAwesomeIcon icon={faFolderOpen} size="lg"/></Button>
                </Tooltip>

                <Tooltip content="Avatar">
                    <Button size={props.size} color={props.color} isIconOnly onClick={toggleAvatar} variant={props.value.avatar ? 'faded' : 'solid'}><FontAwesomeIcon icon={faCircleUser} size="lg"/></Button>
                </Tooltip>

                <LayerMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
            </div>

            <div>
                <p className="text-tiny text-default-500 mb-2">Opacity</p>
                <Input 
                    size={props.size}
                    color={props.color}
                    variant="bordered"
                    value={(props.value.opacity || 100).toString()} 
                    onValueChange={v => setOpacity(parseFloat(v))} 
                    onFocus={highlightValue}
                />
            </div>   

            <Accordion selectionMode="multiple">
                <AccordionItem title="Border">
                    <BorderMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                    <div style={{marginTop: '15px'}}></div>
                    <PaddingMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />

                </AccordionItem>
                <AccordionItem title="Position">
                    <PositionMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                </AccordionItem>
                <AccordionItem title="Rotation">
                    <RotationMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                </AccordionItem>
                <AccordionItem title="Size">
                    <SizeMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                </AccordionItem>
            </Accordion>        
        </div>
    )
}

