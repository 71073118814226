import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { IBadge } from "../../../Models/IBadge";
import { useContext } from "react";
import { BadgeContext } from "../../../Context/badge-context";
import BadgeList from "../../../Components/badge-list";

export default function Gallery() {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {setBadge} = useContext(BadgeContext);

    const select = (badge: IBadge) => {
        setBadge(badge);
        onClose();
    }

    return(
        <>
            <Button 
                onClick={onOpen}
                startContent={<FontAwesomeIcon icon={faSearch} />}
            >
                Browse
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" placement="center" size="2xl" backdrop="blur" classNames={{wrapper: "gallery-modal", backdrop: "gallery-modal"}}>
                <ModalContent>
                    <ModalHeader>
                        <div>
                            Browse (beta)
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Accordion>
                            <AccordionItem title="Professional">
                                <BadgeList type="professional" />
                            </AccordionItem>
                            <AccordionItem title="Entertainment">
                                <BadgeList type="entertainment" />
                            </AccordionItem>
                        </Accordion>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}