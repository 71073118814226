import './Build.css'
import { CircularProgress, Spacer } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { IBadge, IBadgeObject, IFaceImage, IFaceText } from '../../Models/IBadge';
import { BadgeContext } from '../../Context/badge-context';
import { OptionsContext } from '../../Context/options-context';
import { IOptions } from '../../Models/IOptions';
import Options from './Components/badge-options';
import BadgeEditor from './Components/badge-editor';
import Checkout from './Components/checkout/checkout';
import { EditContext } from '../../Context/edit-context';
import { cast, loadSavedBadgeState, resizeBadge, uuidv4 } from '../../utils';
import { useSearchParams } from 'react-router-dom';
import { IBadgeTemplate } from '../../Models/IBadgeTemplate';
import Saver from './Components/saver';
import PageView from '../../Components/page-view';

const savedBadge = loadSavedBadgeState();

export default function Build() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const [badge, setBadge] = useState<IBadge>(savedBadge || {
        landscape: false,
        lanyard: false,
        front: {
            id: uuidv4(),
            objects: [
                cast<IFaceImage>({
                    id: uuidv4(),
                    url: '/logo.png',
                    xPercentage: 50,
                    yPercentage: 25,
                    zIndex: 0,
                    width: 150,
                    height: null,
                    borderWidth: 0
                }),
                cast<IFaceText>({
                    id: uuidv4(),
                    content: `Welcome`,
                    fontSize: 18,
                    xPercentage: 50, 
                    yPercentage: 50,
                    bold: true,
                    zIndex: 1,
                    width: null,
                    height: null,
                    borderWidth: 0,
                    textAlign: 'center',
                }),
                cast<IFaceText>({
                    id: uuidv4(),
                    content: `Use the buttons below to\nadd images and text\nand change the background`,
                    fontSize: 16,
                    xPercentage: 50, 
                    yPercentage: 65,
                    bold: false,
                    zIndex: 1,
                    width: null,
                    height: null,
                    borderWidth: 0,
                    textAlign: 'center',
                }),
                cast<IFaceText>({
                    id: uuidv4(),
                    content: `Click the eraser below to clear me`,
                    fontSize: 14,
                    xPercentage: 50, 
                    yPercentage: 95,
                    bold: false,
                    zIndex: 1,
                    width: null,
                    height: null,
                    borderWidth: 0,
                    textAlign: 'center',
                    italic: true
                })
            ]
        },
        back: {
            id: uuidv4(),
            objects: []
        }
    });

    const [options, setOptions] = useState<IOptions>({
        linkSides: true,
        locked: false
    })

    useEffect(() => {
        const badgeType =  searchParams.get('type');
        const badgeId = searchParams.get('id');
        if(badgeType == null || badgeId == null) return;

        setLoading(true);
        fetch(`${process.env.PUBLIC_URL}/badge-templates/templates.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => json[badgeType])
            .then((templates: IBadgeTemplate[]) => {
                var template = templates.filter(x => x.id.toLowerCase() == badgeId.toLowerCase())[0];

                setOptions({
                    ...options,
                    linkSides: template.badge.back.objects.length == 0
                })

                return template.badge
            })
            .then(badge => {
                if(badge == null) return;
                resizeBadge(badge);
                setBadge(badge)
            })
            .finally(() => setLoading(false))
    }, [searchParams])

    

    const [editContext, setEditContext] = useState<IBadgeObject | null>(null);
    
    useEffect(() => {
        if(editContext == null) return;

        var index = badge.front.objects.findIndex(x => x.id == editContext.id);

        if (index != -1) {
            badge.front.objects[index] = editContext;
        } else {
            index = badge.back.objects.findIndex(x => x.id == editContext.id);

            if (index != -1) {
                badge.back.objects[index] = editContext;
            }
        }

        setBadge({...badge})
    }, [editContext])

    useEffect(() => {
        if(options.locked) setEditContext(null)
    }, [options])

    const onBadgeObjectDeleted = () => {
        if(editContext == null) return;

        var index = badge.front.objects.findIndex(x => x.id == editContext.id);

        if (index != -1) {
            badge.front.objects.splice(index, 1);
        } else {
            index = badge.back.objects.findIndex(x => x.id == editContext.id);

            if (index != -1) {
                badge.back.objects.splice(index, 1);
            }
        }

        setBadge({...badge})
        setEditContext(null)
    }

    const onBadgeObjectCopied = () => {
        if(editContext == null) return;

        var index = badge.front.objects.findIndex(x => x.id == editContext.id);

        if (index != -1) {
            var obj = {... badge.front.objects[index], id: uuidv4()};
            // if(instanceOfIPosition(obj)) {
            //     obj.xPercentage += obj.xPercentage < 20 ? 5 : -5;
            //     obj.yPercentage += obj.yPercentage < 20 ? 5 : -5;
            // }
            badge.front.objects.push(obj);
            setEditContext(obj);
        } else {
            index = badge.back.objects.findIndex(x => x.id == editContext.id);

            if (index != -1) {
                var obj = {... badge.back.objects[index], id: uuidv4()};
                // if(instanceOfIPosition(obj)) {
                //     obj.xPercentage += obj.xPercentage < 20 ? 5 : -5;
                //     obj.yPercentage += obj.yPercentage < 20 ? 5 : -5;
                // }
                badge.back.objects.push(obj);
                setEditContext(obj);
            }
        }

        setBadge({...badge})
    }

    useEffect(() => {
        if (editContext == null) return;
        if (badge.front.objects.findIndex(x => x.id == editContext.id) == -1
            && badge.back.objects.findIndex(x => x.id == editContext.id) == -1) {
           setEditContext(null);
        }
    }, [badge])

    if(loading) return <CircularProgress style={{margin: 'auto'}}/>

    return(
        <BadgeContext.Provider value={{badge, setBadge}}>
            <OptionsContext.Provider value={{options, setOptions}}>
                <EditContext.Provider value={{editContext, setEditContext, onDelete: onBadgeObjectDeleted, onCopy: onBadgeObjectCopied}}>
                    <Display />
                    <Saver />
                    <PageView />
                </EditContext.Provider>
            </OptionsContext.Provider>
        </BadgeContext.Provider>
    )
}


function Display() {
    return (
        <div style={{paddingTop: '30px', paddingLeft: '30px', paddingRight: "30px", paddingBottom: '250px'}}>
            <div className='py-3 px-2 bg-foreground-50 border border-default-300'>
                <Options />
            </div>

            <Spacer y={10} />

            <div className='border px-4 py-3 rounded-xl border-default-300 bg-foreground-50/20'>
                <BadgeEditor />
            </div>

            <div id="checkout-button-container" className='border-t border-warning-300 bg-foreground-50/90'>
                <Checkout />
            </div>
        </div>
    )
}