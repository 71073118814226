import { useContext } from "react";
import { EditContext } from "../../../../Context/edit-context";
import FaceImageMenu from "./face-image";
import FaceTextMenu from "./face-text";
import { Button, Divider, Spacer } from "@nextui-org/react";
import { instanceOfIFaceText, instanceOfIFaceImage } from "../../../../utils";
import { faCheck, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EditContextMenu() {
    const {editContext} = useContext(EditContext);

    if(editContext == null) return <></>

    return (
        <div className='p-4 bg-foreground-50 border rounded-xl border-default-300'>
            <Menu />
        </div>
    )
}

function Menu() {
    const {editContext, setEditContext, onDelete, onCopy} = useContext(EditContext);
    const formSize = "md"

    const copyAndDelete = (color: any) =>
        <div style={{display: 'flex', gap: '15px'}}>
            <div className="d-small">
                <p className="text-tiny text-default-500 mb-1">Apply</p>
                <Button color={color} isIconOnly onClick={() => setEditContext(null)}><FontAwesomeIcon icon={faCheck}/></Button>
            </div>
            <div>
                <p className="text-tiny text-default-500 mb-1">Copy</p>
                <Button color={color} isIconOnly onClick={onCopy}><FontAwesomeIcon icon={faCopy}/></Button>
            </div>

            <div>
                <p className="text-tiny text-default-500 mb-1">Delete</p>
                <Button color="danger" isIconOnly onClick={onDelete}><FontAwesomeIcon icon={faTrash}/></Button>
            </div>
        </div>
    ;
    

    if(instanceOfIFaceImage(editContext)) return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="text-lg text-default-1000">Edit Image</p>
                {copyAndDelete("primary")}
            </div>  
            <Spacer y={2} />  
            <Divider />
            <Spacer y={2} />        
            <FaceImageMenu size={formSize} color="primary" value={editContext} onValueChanged={setEditContext} />
        </div>
    )

    if(instanceOfIFaceText(editContext)) return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="text-lg text-default-1000">Edit Text</p>
                {copyAndDelete("secondary")}
            </div>
            <Spacer y={2} />  
            <Divider />
            <Spacer y={2} /> 
            <FaceTextMenu size={formSize} color="secondary" value={editContext} onValueChanged={setEditContext} />
        </div>
    )

    return <></>
}