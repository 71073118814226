import { useContext, useEffect, useState } from "react";
import { BadgeContext } from "../../../Context/badge-context";
import { saveBadgeState } from "../../../utils";


export default function Saver() {
    const {badge, setBadge} = useContext(BadgeContext);
    const [interval, setStateInterval] = useState<any>(null);

    useEffect(() => {
        if(interval != null) return;

        setStateInterval(setInterval(() => {
            try {
                saveBadgeState(badge);
            } catch {
                console.error("could not save badge state")
            }
        }, 10 * 1000))

        return () => {
            clearInterval(interval);
            setStateInterval(null);
        }
    }, [badge])

    return <></>
}