import { CSSProperties, useEffect, useState } from "react";
import { IPosition } from "../../../../../Models/IBadge";

export default function usePositionStyles(position: IPosition) {
    const calculate = (): CSSProperties => {
        return {
            position: 'absolute', 
            transform: `translate(-50%, -50%) rotate(${position.rotation || 0}deg)`,
            top: `${position.yPercentage}%`, 
            left: `${position.xPercentage}%`,
            zIndex: position.zIndex,
        }
    }

    const [styles, setStyles] = useState<CSSProperties>(calculate());

    useEffect(() => {
        setStyles(calculate())
    }, [position]);

    return styles;
}