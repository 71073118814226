import { Avatar, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, Image } from "@nextui-org/react";

export default function Nav() {
    return (
        <Navbar isBordered maxWidth="2xl">
            <NavbarBrand>
                <Link className="font-bold text-inherit" color="foreground" href="/">
                    <Image src="/logo.png" width={36}/>
                    <p className="font-bold text-inherit ml-2">Build A Badge</p>
                </Link>                
            </NavbarBrand>

            <NavbarContent justify="end">
                <NavbarItem>
                    <Link href="/build">Build</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link href="/contact">Contact Us</Link>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}