import { createContext } from "react";
import { IOptions } from "../Models/IOptions";

type OptionsSetter = (options: IOptions) => void;

export const OptionsContext = createContext<{
    options: IOptions,
    setOptions: OptionsSetter
}>({
    options: {
        linkSides: false,
        locked: false
    },
    setOptions: () => {}
})