import { Button, ButtonGroup, Tooltip } from "@nextui-org/react"
import { IPosition } from "../../../../Models/IBadge"
import { faAlignLeft, faAlignCenter, faAlignRight, faArrowsUpToLine, faArrowsDownToLine, faAnglesUp, faAnglesDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type PositionChanged = (position: IPosition) => void;
export default function LayerMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IPosition,
    onValueChanged: PositionChanged
}) {
    const layerUp = () => props.onValueChanged({...props.value, zIndex: props.value.zIndex + 1})
    const layerDown = () => props.onValueChanged({...props.value, zIndex: props.value.zIndex - 1})


    return (
        <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
            <ButtonGroup color={props.color} size={props.size}>
                <Tooltip content="Layer Up">
                    <Button onClick={layerUp} isIconOnly><FontAwesomeIcon icon={faAnglesUp} size="lg"/></Button>
                </Tooltip>
                <Tooltip content="Layer Down">
                    <Button onClick={layerDown} isIconOnly><FontAwesomeIcon icon={faAnglesDown} size="lg"/></Button>
                </Tooltip>
            </ButtonGroup>
        </div>
    )
}