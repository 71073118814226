import { Card, Spacer } from "@nextui-org/react";
import { useContext, useEffect } from "react";
import { BadgeContext } from "../../../../Context/badge-context";
import { FaceContext } from "../../../../Context/face-context";
import Face from "./face";
import FaceControls from "./face-controls";
import { IFaceText, IPosition, ISize } from "../../../../Models/IBadge";
import { EditContext } from "../../../../Context/edit-context";
import { cast, instanceOfIPosition, instanceOfISize, uuidv4 } from "../../../../utils";
import interact from "interactjs";

export default function FaceEditor() {
    const {badge} = useContext(BadgeContext);
    const {face, setFace} = useContext(FaceContext);
    const {editContext, setEditContext} = useContext(EditContext);

    useEffect(() => {
        var interactables = editContext == null || face.objects.findIndex(x => x.id == editContext.id) == -1
        ? []
        : setupDrag();

        return () => {
            for(var interactable of interactables) interactable?.unset()
        };
    }, [editContext, face])

    const setupDrag = () => {
        if(!instanceOfIPosition(editContext) || !instanceOfISize(editContext)) return [];

        const element = document.getElementById(`${face.id}-${editContext.id}`);
        if(element == null) return [];

        const badgeObjectId = editContext.id;

        element.setAttribute('data-top', editContext.yPercentage.toString());
        element.setAttribute('data-left', editContext.xPercentage.toString());        

        var interatable = interact(element!)
            .draggable({  
                delay: 50,
                ignoreFrom: '.rotate-box',
                modifiers: [
                    interact.modifiers.restrict({
                      restriction: document.getElementById(face.id)!,
                    }),
                    interact.modifiers.snap({
                        enabled: true,
                        targets: [
                            interact.snappers.grid({ x: 5, y: 5 }),
                        ],
                        relativePoints: [ 
                            { x: 0  , y: 0   },
                            { x: 0.5, y: 0.5 },
                            { x: 1  , y: 1   } 
                        ],
                        offset: 'self',
                        range: Infinity,
                      })
                ],
                listeners: { 
                    move: dragMoveListener,
                    end (event) {
                        dragged(element, badgeObjectId);
                    }
                }
            })
            .resizable({
                delay: 50,
                edges: { left: `.resize-left`, right: `.resize-right`, bottom: `.resize-bottom`, top: `.resize-top` },
                listeners: {
                    move: resizeListener,
                    end (event) {
                        resized(element, badgeObjectId);
                    }
                }
            });

        const rotateElement = element.getElementsByClassName('rotate-box')[0] as HTMLElement;

        const getCenter = (el: HTMLElement) => {
            const rect = el.getBoundingClientRect();
            return {
                x: rect.left + (rect.width / 2),
                y: rect.top + (rect.height / 2)
            }
        }

        const elementCenter = getCenter(element);
        const rotateElementCenter = getCenter(rotateElement);

        const x = rotateElementCenter.x - elementCenter.x;
        const y = elementCenter.y - rotateElementCenter.y;

        rotateElement.setAttribute('data-x', x.toString());
        rotateElement.setAttribute('data-y', y.toString());

        var rotateInteractable = interact(rotateElement!)
            .draggable({  
                delay: 50,
                listeners: { 
                    move: e => rotateListener(e, element),
                    end () {
                        rotated(element, badgeObjectId);
                    }
                }
            })

        return [interatable, rotateInteractable];
    }

    function resizeListener(event: any) {
        var target = event.target

        var edgeCount = event.edges.left + event.edges.right + event.edges.top + event.edges.bottom;
        var preserveAspectRatio = edgeCount > 1;

        if(preserveAspectRatio) {
            var amountToMove = event.dx * (event.edges.left ? - 1 : 1);
            
            var width = parseFloat(target.style.width.substring(0, target.style.width.length - 2));
            var height = parseFloat(target.style.height.substring(0, target.style.height.length - 2));

            if(isNaN(width)) width = event.rect.width;
            if(isNaN(height)) height = event.rect.height;

            var aspectRatio = height / width;

            target.style.width = (width + amountToMove) + 'px'
            target.style.height = (height + (amountToMove * aspectRatio)) + 'px'
        } else {
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'
        }  
    }

    function resized(element: HTMLElement, badgeObjectId: string) {
        const index = face.objects.findIndex(x => x.id == badgeObjectId);
        const object = face.objects[index] as ISize;

        // substring where 'px' not included in number
        object.width = parseFloat(element.style.width.substring(0, element.style.width.length - 2));
        object.height = parseFloat(element.style.height.substring(0, element.style.height.length - 2));

        face.objects[index] = {...object}
        setFace({...face})
    }

    function dragMoveListener(event: any) {
        var target = event.target

        const parent = document.getElementById(face.id)!;
        const boundingRect = parent.getBoundingClientRect()

        const dx = (event.dx / boundingRect.width) * 100;
        const dy = (event.dy / boundingRect.height) * 100;

        var left = parseFloat(target.getAttribute('data-left') || 0) + dx;
        var top = parseFloat(target.getAttribute('data-top') || 0) + dy;

        target.setAttribute('data-left', left)
        target.setAttribute('data-top', top)

        target.style.left = `${left}%`;
        target.style.top = `${top}%`;
    }

    const dragged = (element: HTMLElement, badgeObjectId: string) => {
        var left = parseFloat(element.getAttribute('data-left')!);
        var top = parseFloat(element.getAttribute('data-top')!);

        // reset element
        element.removeAttribute('data-left')
        element.removeAttribute('data-top')

        const index = face.objects.findIndex(x => x.id == badgeObjectId);
        const object = face.objects[index] as IPosition;

        object.xPercentage = left;
        object.yPercentage = top;

        face.objects[index] = {...object}
        setFace({...face})
    }

    const rotateListener = (event: any, rootElement: HTMLElement) => {
        const calcAngleDegrees = (cx: number, cy: number, ex: number, ey: number)  =>{
            var dy = ey - cy;
            var dx = ex - cx;
            var theta = Math.atan2(dy, dx); // range (-PI, PI]
            theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
            if (theta < 0) theta = 360 + theta; // range [0, 360)
            return 90 - theta;
        }

        let x = parseFloat(event.target.getAttribute('data-x')!);
        let y = parseFloat(event.target.getAttribute('data-y')!);

        let newX = x + event.dx;
        let newY = y - event.dy;

        let angle = calcAngleDegrees(0, 0, newX, newY);

        event.target.setAttribute('data-x', newX.toString());
        event.target.setAttribute('data-y', newY.toString());

        rootElement.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        rootElement.setAttribute('data-rot', angle.toString())
    }

    const rotated = (element: HTMLElement, badgeObjectId: string) => {
        var rotation = parseFloat(element.getAttribute('data-rot')!);

        element.removeAttribute('data-rot');

        const index = face.objects.findIndex(x => x.id == badgeObjectId);
        const object = face.objects[index] as IPosition;

        object.rotation = rotation;

        face.objects[index] = {...object}
        setFace({...face})
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div onClick={() => setEditContext(null)}>
                <Card>
                    <Face landscape={badge.landscape} lanyard={badge.lanyard} face={face} scollable={false}/>
                </Card>
            </div>
            
            <Spacer y={3} />

            <div>
                <FaceControls />
            </div>
        </div>
    )
}