import { faArrowLeft, faArrowRight, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ResizerArrows() {
    return (
        <>
            <div data-html2canvas-ignore className="resize-left" style={{position: 'absolute', left: '-5%', top: '50%', transform: 'translate(-100%, -50%)', color: 'black', fontSize: 'xx-small'}}>
                <FontAwesomeIcon icon={faArrowLeft}  />
            </div>
            <div data-html2canvas-ignore className="resize-right" style={{position: 'absolute', right: '-5%', top: '50%', transform: 'translate(100%, -50%)', color: 'black', fontSize: 'xx-small'}}>
                <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <div data-html2canvas-ignore className="resize-top" style={{position: 'absolute', left: '50%', top: '-5%', transform: 'translate(-50%, -100%)', color: 'black', fontSize: 'xx-small'}}>
                <FontAwesomeIcon icon={faArrowUp} />
            </div>
            <div data-html2canvas-ignore className="resize-bottom" style={{position: 'absolute', left: '50%', bottom: '-5%', transform: 'translate(-50%, 100%)', color: 'black', fontSize: 'xx-small'}}>
                <FontAwesomeIcon icon={faArrowDown} />
            </div>

            <div data-html2canvas-ignore className="resize-left resize-bottom" style={{position: 'absolute', left: '0%', bottom: '-5%', transform: 'translate(-150%, 80%) rotate(45deg)', color: 'black', fontSize: 'small'}}>
                <FontAwesomeIcon icon={faArrowDown}/>
            </div>
            
            <div data-html2canvas-ignore className="resize-left resize-top" style={{position: 'absolute', left: '0%', top: '-5%', transform: 'translate(-150%, -80%) rotate(135deg)', color: 'black', fontSize: 'small'}}>
                <FontAwesomeIcon icon={faArrowDown}/>
            </div>

            <div data-html2canvas-ignore className="resize-right resize-top" style={{position: 'absolute', right: '0%', top: '-5%', transform: 'translate(150%, -80%) rotate(-135deg)', color: 'black', fontSize: 'small'}}>
                <FontAwesomeIcon icon={faArrowDown}/>
            </div>

            <div data-html2canvas-ignore className="resize-right resize-bottom" style={{position: 'absolute', right: '0%', bottom: '-5%', transform: 'translate(150%, 80%) rotate(-45deg)', color: 'black', fontSize: 'small'}}>
                <FontAwesomeIcon icon={faArrowDown}/>
            </div>
        </>
    )
}