import { Button, Input } from "@nextui-org/react";
import { IBorder } from "../../../../Models/IBadge";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { highlightValue } from "../../../../utils";
import ColorPicker from "./color-picker";

type BorderChanged = (border: IBorder) => void;
export default function BorderMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IBorder,
    onValueChanged: BorderChanged
}) {

    const reset = () => props.onValueChanged({...props.value, 
        borderWidth: 0,
        borderColor: undefined,
        borderRadius: 0
    })
    const setColor = (borderColor: string) => {
        props.onValueChanged({...props.value, 
            borderColor, 
            borderWidth: props.value.borderWidth > 0 ? props.value.borderWidth : 1
        })
    }
    const setBorderWidth = (borderWidth: number) => props.onValueChanged({...props.value, borderWidth})
    const setBorderRadius= (borderRadius: number) => props.onValueChanged({...props.value, borderRadius})


    return (
        <div>
            <div style={{display: 'grid', gridTemplateColumns: 'min-content 75px 75px auto', gap: '15px'}}>
                <div>
                    <p className="text-tiny text-default-500 mb-1">Color</p>
                    <ColorPicker color={props.color} value={props.value.borderColor} onValueChange={setColor}/>
                </div>

                <div>
                    <p className="text-tiny text-default-500 mb-1">Size</p>
                    <Input
                        size={props.size}
                        type="number"
                        onFocus={highlightValue}
                        value={(props.value.borderWidth || 0).toString()}
                        onValueChange={v => setBorderWidth(parseFloat(v))}
                    /> 
                </div>
                

                <div>
                    <p className="text-tiny text-default-500 mb-1">Radius</p>
                    <Input
                        size={props.size}
                        type="number"
                        onFocus={highlightValue}
                        value={(props.value.borderRadius || 0).toString()}
                        onValueChange={v => setBorderRadius(parseFloat(v))}
                    /> 
                </div>
                
                <div>
                    <p className="text-tiny text-default-500 mb-1">Reset</p>
                    <Button color="danger" isIconOnly onClick={reset}><FontAwesomeIcon icon={faTrash} size="lg"/></Button>

                </div>
            </div>
        </div>
    )
}