import html2canvas from "html2canvas";
import { IPosition, ISize, IFaceText, IFaceImage, IBorder, IBadge, IFace, IBadgeObject } from "./Models/IBadge";

export function loadFile(callback: any, fileType: string) {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = fileType;

    input.onchange = (e: any) => { 
        if(e.target?.files == null) return;
        var file = e.target.files[0]; 
        callback(file);
    }

    input.click();
}

export function loadImage(callback: any) {
    loadFile((file: any) => {
         var reader = new FileReader();
         reader.readAsDataURL(file);
 
         reader.onload = readerEvent => {
             callback(readerEvent!.target!.result!.toString());
         }
    }, "image/png, image/jpg, image/jpeg, image/webp");
}

export function setCookie(name: string, value: string) {
    document.cookie = name + "=" + (value || "") + "; path=/; expires=Thu, 18 Dec 2113 12:00:00 UTC";
  }
  
  export function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}


export function highlightValue(e: any) {
    const element = e.target;
    if(element == null) return;
    element.select();
}

export function instanceOfIPosition(object: any): object is IPosition {
    return object != null && 'xPercentage' in object;
}

export function instanceOfISize(object: any): object is ISize {
    return object != null && 'width' in object;
}

export function instanceOfIBorder(object: any): object is IBorder {
    return object != null && 'borderWidth' in object;
}


export function instanceOfIFaceText(object: any): object is IFaceText {
    return object != null && 'content' in object;
}

export function instanceOfIFaceImage(object: any): object is IFaceImage {
    return object != null && 'url' in object;
}

export function cast<T>(obj: T) {
    return obj;
}

export function copyBadge(badge: IBadge): IBadge {
    return {
        ...badge,
        front: copyFace(badge.front),
        back: copyFace(badge.back)
    }
}

export function copyFace(face: IFace) {
    return cast<IFace>(
        {
            ...face,
            id: uuidv4(),
            objects: face.objects.map(x => { return {...x, id: uuidv4()}}),
        }
    )
}

export function equal(left: IBadge, right:IBadge) {
    const copyAndDeleteIds = (badge: IBadge) => {
        return cast<IBadge>(
            {
                ...badge,
                front: {
                    ...badge.front,
                    id: "copied",
                    objects: badge.front.objects.map(obj => {
                        return {
                            ...obj,
                            id: "copied"
                        }
                    })
                },
                back: {
                    ...badge.back,
                    id: "copied",
                    objects: badge.back.objects.map(obj => {
                        return {
                            ...obj,
                            id: "copied"
                        }
                    })
                }
            }
        )
    }

    left = copyAndDeleteIds(left);
    right = copyAndDeleteIds(right);

    return JSON.stringify(left) == JSON.stringify(right);
}

export function loadSavedBadgeState(): IBadge | null {
    var badge = localStorage.getItem('badge');
    if(badge == null) return null;

    return JSON.parse(badge);
}

export function saveBadgeState(badge: IBadge) {
    localStorage.setItem('badge', JSON.stringify(badge));
}

export function shuffle(array: any[]) {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function resizeBadge(badge: IBadge) {
    if(window.screen.width > 600) return; // same as face.css

    const ratio = (200 / 300); // has to be same as laynard in face.css
    const resizeObjects = (objects: IBadgeObject[]) => {
        return objects.map(object => {
            if(instanceOfISize(object)) {
                if(object.width) object.width *= ratio;
                if(object.height) object.height *= ratio;
            }

            if(instanceOfIFaceText(object)) {
                console.log(object.fontSize)
                if(object.fontSize) object.fontSize *= ratio;
                console.log(object.fontSize)
            }

            return object;
        })
    }
    
    if(badge.landscape) {
        badge.front.objects = resizeObjects(badge.front.objects);
        badge.back.objects = resizeObjects(badge.back.objects);
    }
}

const EmailRegex = /^\S+@\S+\.\S+$/;

export function isValidEmail(email: string) {
    return email.match(EmailRegex) != null
}

export async function getFaceImages(containerId: string) {
    const cardFaces =  document.getElementById(containerId)!.getElementsByClassName('face')!;
    const images: Blob[] = [];
    for(let i = 0; i < cardFaces.length; i++) {
        const cardFace = cardFaces[i];
        if(cardFace instanceof HTMLDivElement) {
            const canvas = await html2canvas(cardFace, {
                scale: 5
            });
            const blob: Blob | null = await new Promise(resolve => canvas.toBlob(resolve));
            images.push(blob!);
        }
    }

    return images;
}