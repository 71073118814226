import "./About.css"
import { faArrowRight, faChevronDown, faHammer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Link, Spacer, Button, CircularProgress, Image } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { IBadgeTemplate } from "../Models/IBadgeTemplate";
import { resizeBadge } from "../utils";
import Face from "./Builder/Components/face/face";

export default function About() {
    const goToBadges = () => {
        const element = document.getElementById("badges")!;
        element.scrollIntoView({behavior: "smooth"});
    }

    return (
        <div style={{paddingTop: '30px', paddingLeft: '30px', paddingRight: "30px"}}>
            <div id="editor-container">
                <div>
                    <div className="text-center text-5xl text-white font-medium">
                        <span className="decoration-warning decoration-2 underline underline-offset-8">Badges</span>, Built <ForYouByYou/> You
                    </div>

                    <Spacer y={5} />

                    <div className="text-center text-lg text-default-500 font-semibold">
                        Choose from our curated badges or make your own
                    </div>

                    <Spacer y={5} />

                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <div >
                            <Button 
                                color="warning" 
                                variant="bordered" 
                                size="lg" 
                                as={Link} 
                                href="build"
                                startContent={<FontAwesomeIcon icon={faHammer}/>}
                                endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                            >
                                Make Your Own
                            </Button>
                        </div>

                        <Spacer y={5} />

                        <div>
                            <Button 
                                color="warning" 
                                variant="light" 
                                size="sm" 
                                onClick={goToBadges}
                                endContent={<FontAwesomeIcon icon={faChevronDown}/>}
                                >Show Me Some Options</Button>
                        </div>
                    </div>
                </div>

                <div className="d-big">
                    <video autoPlay muted loop>
                        <source src="/images/recording.mov" type="video/mp4" />
                    </video>
                </div>
            </div>
            
            <div id="badges">
                <div style={{height: '4rem'}}></div>
                <FeaturedBadges type="professional" description="We have a wide range of fully customizable professional looking badges for your employees, students, guests, and more."/>
            </div>
            <Spacer y={20} />
            <div id="entertainment">
                <FeaturedBadges nsfw={true} type="entertainment" description="Trying to find a gag joke for a friend? We have you covered. Browse our entertainment badges and customize them with your own inside jokes or upload a photo to fool a friend."/>
            </div>

            <Spacer y={20} />

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Card style={{maxWidth: '450px'}}>
                    <Image 
                        src="/images/custom.jpg"
                        className="h-[340px] object-cover border-b"
                    />

                    <div className="p-4">
                        <div className="text-3xl text-white uppercase font-light tracking-widest">
                            Custom
                        </div>

                        <Spacer y={5} />

                        <div className="text-default-500">
                            Click the button below to let your imagination run wild and build your own custom badge from scratch  
                        </div>

                        <Spacer y={3} />

                        <Button 
                            fullWidth
                            color="warning" 
                            size="lg" 
                            as={Link} 
                            href="build"
                            startContent={<FontAwesomeIcon icon={faHammer}/>}
                            endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                        >
                            Make Your Own
                        </Button>
                    </div>
                </Card>
            </div>

            <Spacer y={20} />
            <div className="border-t text-center p-4">
                <Link href="/privacy" className="mr-2">Privacy Policy</Link>
                <Link href="/contact">Contact Us</Link>

                <div className="text-tiny">
                    Copyright 2024
                </div>
            </div>
        </div>
    )
}

function ForYouByYou() {
    const [word, setWord] = useState('For')
    return <span onAnimationIteration={() => setWord(word == 'For' ? `By` : 'For')} style={{display: 'inline-block', width: '65px', animationName: 'forbyyou', animationDuration: '1.5s', animationIterationCount: 'infinite', animationDirection: 'alternate'}}>{word}</span>
}

function FeaturedBadges(props: {type: string, description: string, nsfw?: boolean}) {
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState<IBadgeTemplate[]>([]);

    useEffect(() => {
        if(templates.length > 0) return;

        setLoading(true);
        fetch(`${process.env.PUBLIC_URL}/badge-templates/templates.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => [...json[props.type].slice(0, 3)])
            .then((templates: IBadgeTemplate[]) => {
                for(var template of templates) {
                    resizeBadge(template.badge);
                }
                return templates;
            })
            .then(setTemplates)
            .finally(() => setLoading(false));
    }, [])

    return (
        <div style={{position: 'relative', padding: '75px'}}>
            <div className="blur-sm" style={{opacity: 0.4, zIndex: -1, position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', backgroundImage: `url('/images/${props.type}.jpg')`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            </div>

            <div className={`badge-type-container badge-type-container-${props.type}`}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="py-4 px-10 text-center" style={{backgroundColor: '#18181b', borderRadius: '15px'}}>
                        <div className="text-default-500">
                            Featured
                        </div>
                        <div className="text-3xl text-white uppercase font-light tracking-widest">
                            {props.type}
                        </div>

                        <Spacer y={5} />

                        <div className="text-md text-default-700">
                            {props.description}
                        </div>

                        {props.nsfw && <div className="text-tiny text-default-500">NSFW</div>}

                        <Spacer y={5} />

                        <Button  
                            color="warning" 
                            fullWidth 
                            className="mt-2" 
                            as={Link} 
                            href={`/${props.type}`}
                            endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                            >See More</Button>
                    </div>
                </div>

                {loading 
                ? <CircularProgress />
                : (
                    <div style={{display: 'flex', gap: '50px', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', transform: 'scale(1)'}}>
                        {templates.map((template, i) => (
                            <Card isPressable as={Link} href={`/build?type=${props.type}&id=${template.id}`} className={props.nsfw ? 'blur-lg' : ''}>
                                <div style={{display: 'flex'}}>
                                    <Face key={i} face={template.badge.front} landscape={template.badge.landscape} lanyard={template.badge.lanyard} scollable={true} />
                                </div>
                            </Card>
                        ))}
                    </div>
                )}
            </div>
            
            
        </div>
    )
}