import { Input } from "@nextui-org/react";
import { ISize } from "../../../../Models/IBadge";
import { highlightValue } from "../../../../utils";


type SizeChanged = (size: ISize) => void;
export default function SizeMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: ISize,
    onValueChanged: SizeChanged
}) {
    const setWidth = (width: number) => props.onValueChanged({...props.value, width});
    const setHeight = (height: number) => props.onValueChanged({...props.value, height});

    return (
        <div style={{display: 'flex', gap: '10px'}}>
            <div>
                <p className="text-tiny text-default-500 mb-1">Width</p>
                <Input 
                    color={props.color}
                    size={props.size}
                    variant="bordered"
                    type="number" 
                    value={props.value.width?.toString()} 
                    onValueChange={v => setWidth(parseInt(v))}
                    endContent={<div>px</div>}
                    onFocus={highlightValue}
                />
            </div>

            <div>
                <p className="text-tiny text-default-500 mb-1">Height</p>
                <Input 
                    color={props.color}
                    size={props.size}
                    variant="bordered"
                    type="number" 
                    value={props.value.height?.toString()} 
                    onValueChange={v => setHeight(parseInt(v))}
                    endContent={<div>px</div>}
                    onFocus={highlightValue}
                />
            </div>
        </div>
    )
}