import "./checkout.css"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, useDisclosure } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import EmailVerification from "./email-verification";
import Payment from "./payment";
import { getCookie, setCookie, isValidEmail as emailValidator } from "../../../../utils";
import { OptionsContext } from "../../../../Context/options-context";


export default function Checkout() {
    const SavedEmail = getCookie('email');
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [email, setEmail] = useState(SavedEmail || '');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [verifiedEmail, setVerifiedEmail] = useState(SavedEmail != null);
    const [verifyingEmail, setVerifyingEmail] = useState(false);
    const {options, setOptions} = useContext(OptionsContext);

    useEffect(() => {
        setIsValidEmail(emailValidator(email))
        setVerifiedEmail(email == SavedEmail);
    }, [email])
    
    useEffect(() => {
        setVerifyingEmail(false);
        if(verifiedEmail) setCookie('email', email)
    }, [verifiedEmail])

    useEffect(() => {
        setVerifyingEmail(false)        
        setOptions({...options, locked: isOpen})
    }, [isOpen])

    return (
        <div>
            <Button 
                startContent={<FontAwesomeIcon icon={faShoppingCart}/>}
                color="warning"
                onClick={onOpen}
                fullWidth
                size="lg"
                className="text-lg"
            >
                Checkout $6.99
            </Button>

            {!verifiedEmail && (
                <div className="text-default-500 mt-2 text-center">Verify your email during checkout for a <span className="font-bold">First Badge Discount</span></div>
            )}

            <Modal isOpen={isOpen} onClose={onClose} placement="center"  size="3xl" scrollBehavior="inside" classNames={{wrapper: 'checkout-modal', backdrop: 'checkout-modal-backdrop'}} backdrop="blur">
                {!verifyingEmail && 
                    <Payment 
                        email={email}
                        setEmail={setEmail}
                        isValidEmail={isValidEmail}
                        verifiedEmail={verifiedEmail}
                        onVerifyEmail={() => setVerifyingEmail(true)}
                        onCancel={onClose}
                    />
                }

                {verifyingEmail && 
                    <EmailVerification 
                        email={email}
                        setEmail={setEmail}
                        isValidEmail={isValidEmail}
                        onCancel={() => setVerifyingEmail(false)}
                        onVerified={() => setVerifiedEmail(true)}
                    />
                }
            </Modal>
        </div>
    )
}