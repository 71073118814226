import "./Privacy.css"
import { marked } from "marked";
import { useEffect, useState } from "react";

export default function PrivacyPolicy() {
    var markdown = marked.parse(`
# Privacy Policy  
**Effective Date:** November, 24, 2024 

Welcome to Build A Badge! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services at **[https://build-a-badge.com](https://build-a-badge.com)**.  

---

## 1. Information We Collect  
When you use Build A Badge, we may collect the following types of information:  

### a. Personal Information You Provide to Us  
- Name  
- Email address  
- Billing address  
- Shipping address  
- Payment information (processed securely via Stripe)  

### b. Automatically Collected Information  
- IP address  
- Browser type and version  
- Device type and operating system  
- Pages visited and time spent on the website  

### c. Cookies and Tracking Technologies  
We use cookies and similar technologies to improve your experience, analyze site usage, and provide relevant advertisements.  

---

## 2. How We Use Your Information  
We use your information to:  
- Process and fulfill your orders.  
- Communicate with you regarding your orders or inquiries.  
- Improve our website and services.  
- Comply with legal requirements.  

---

## 3. Payment Processing  
We use **Stripe**, a trusted third-party payment processor, to handle all payment transactions. Build A Badge does not store your credit card or payment information. Stripe processes your payment information in accordance with its own [Privacy Policy](https://stripe.com/privacy).  

---

## 4. Sharing of Information  
We do not sell, rent, or share your personal information with third parties, except as necessary to:  
- Process payments through Stripe.  
- Ship your orders via third-party shipping providers.  
- Comply with legal obligations or protect our rights.  

---

## 5. Data Security  
We implement appropriate technical and organizational measures to protect your information from unauthorized access, alteration, disclosure, or destruction.  

---

## 6. Your Choices  
- **Manage Cookies:** You can manage or disable cookies through your browser settings.  

---

## 7. Third-Party Links  
Our website may contain links to third-party websites. We are not responsible for their privacy practices.  

---

## 8. Changes to This Policy  
We may update this Privacy Policy from time to time. Changes will be posted on this page with the updated effective date.  

---

## 9. Contact Us  
If you have any questions about this Privacy Policy or how we handle your information, please contact us at:  
- **Email:** support@build-a-badge.com  
- **Website:** [https://build-a-badge.com](https://build-a-badge.com)  

---

By using **[https://build-a-badge.com](https://build-a-badge.com)**, you agree to the terms outlined in this Privacy Policy.  

    `, {
        gfm: true,
        async: false
    }).toString();

    useEffect(() => {
        document.getElementById('privacy-policy')!.innerHTML = markdown
    }, [])

    return <div id="privacy-policy" style={{maxWidth: '1000px', margin: 'auto'}}></div>
}