import "./badge-options.css"
import { Accordion, AccordionItem, Button, ButtonGroup, Checkbox, CheckboxGroup, Input } from "@nextui-org/react";
import { useContext } from "react";
import { BadgeContext } from "../../../Context/badge-context";
import { OptionsContext } from "../../../Context/options-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { loadFile } from "../../../utils";
import saveAs from "file-saver";
import Gallery from "./gallery";
import Help from "./help";
import { IBadge } from "../../../Models/IBadge";

export default function Options() {
    const {badge, setBadge} = useContext(BadgeContext);
    const {options, setOptions} = useContext(OptionsContext);

    const setName = (name: string) => setBadge({...badge, name});
    
    const getValueForCheckboxes = () => {
        const selected = [];
        if(badge.landscape) selected.push('landscape');
        if(badge.lanyard) selected.push('lanyard');
        if(options.linkSides) selected.push('link-sides');
        return selected;
    }

    const updateOptions = (x: string[]) => {
        setBadge({
            ...badge,
            landscape: x.includes('landscape'),
            lanyard: !x.includes('landscape') && x.includes('lanyard')
        })

        setOptions({
            ...options,
            linkSides: x.includes('link-sides')
        })
    }

    const save = () => {
        var fileName = `${badge.name || 'mybadge'}-buildabadge.json`;
        var fileToSave = new Blob([JSON.stringify(badge)], {
            type: 'application/json'
        });        
        saveAs(fileToSave, fileName);
    }

    const load = () => {
        loadFile((file: any) => {
            var reader = new FileReader();
            reader.readAsText(file);
    
            reader.onload = readerEvent => {
                setBadge(JSON.parse(readerEvent!.target!.result!.toString()));
            }
       }, "application/JSON");
    }

    const el = 
    <div id="badge-options-container">
        <div style={{display: 'flex', gap: '15px', alignItems: 'center'}}>
            <Input 
                value={badge.name} 
                onValueChange={setName} 
                label="Badge Name (optional)"
                size="md"
            />

            <div className="d-small">
                <Help />
            </div>
        </div>

        <ButtonGroup size="md">
            <Gallery />
            <Button onClick={save} color="success" variant="flat" startContent={<FontAwesomeIcon icon={faFileExport}/>}>Export</Button>
            <Button onClick={load} color="primary" variant="flat" startContent={<FontAwesomeIcon icon={faFileImport}/>}>Import</Button>
        </ButtonGroup>

        <CheckboxGroup
            color="warning"
            value={getValueForCheckboxes()}
            onValueChange={updateOptions}
            orientation="horizontal"
        >
            <Checkbox value="landscape">
                Landscape
                <br />
                <span className="text-tiny text-default-500">Change orientation to landscape</span>
            </Checkbox>
            <Checkbox value="lanyard" isDisabled={badge.landscape}>
                Lanyard
                <br />
                <span className="text-tiny text-default-500">Add hole for a lanyard</span>
            </Checkbox>
            <Checkbox value="link-sides">
                Link Sides
                <br />
                <span className="text-tiny text-default-500">Make front and back sides the same</span>
            </Checkbox>
        </CheckboxGroup>

        <div className="d-big" >
            <div style={{display: 'flex', justifyContent: 'end'}}>
                <Help />
            </div>
        </div>
    </div>

    return (
        <>
            <Accordion className="d-small">
                <AccordionItem title="Options">
                    {el}
                </AccordionItem>
            </Accordion>

            <div className="d-big">
                {el}
            </div>
        </>

    )
}