import { faFill, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@nextui-org/react";

export default function ColorPicker(props: {
    value?: string | null, 
    onValueChange: any, color?: any,
    icon?: IconDefinition,
    tooltip?: string,
    size?: "sm" | "md" | "lg", 
}) {
    return (
        <Tooltip content={props.tooltip}>
            <Button isIconOnly color={props.color || 'default'} size={props.size}>
                <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center"}}>
                    <FontAwesomeIcon icon={props.icon || faFill} style={{color: props.value || ''}} size="lg"/>

                    <input type="color" value={props.value || ''} onChange={e => props.onValueChange(e.target.value)} style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}} />
                </div>
            </Button>
        </Tooltip>
    )
}