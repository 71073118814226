import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { useState, useEffect } from "react";
import CodeVerification from "./code-verification";

export default function EmailVerification(props: {
    email: string,
    setEmail: any,
    isValidEmail: boolean,
    onCancel: any,
    onVerified: any
}) {    
    const [isLoading, setIsLoading] = useState(false);
    const [sent, setSent] = useState(false);

    useEffect(() => {
        if (isLoading) setSent(false);
    }, [isLoading])

    const startVerification = async () => {
        try {
            setIsLoading(true);

            var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/start-verify-identity`, {
                method: 'POST',
                body: JSON.stringify({
                    email: props.email
                })
            });
            
            if(!res.ok) throw new Error();

            setSent(true);
        }
        catch {
            // todo 
        } 
        finally {
            setIsLoading(false);
        }
    }

    if(sent) return <CodeVerification 
        email={props.email} 
        onCancel={() => setSent(false)} 
        onVerified={props.onVerified}
    />

    return (
        <ModalContent>
            <ModalHeader>Verify Email</ModalHeader>
            <ModalBody>
                <div>
                    <Input 
                        label="Email"
                        description="Enter your email to receive a verification code"
                        startContent={<FontAwesomeIcon icon={faEnvelope} />}
                        type="email" 
                        value={props.email} 
                        onValueChange={props.setEmail} 
                        isRequired
                    />

                    {sent && (
                        <blockquote className="text-center border px-4 py-3 rounded-xl [&>p]:m-0 border-warning-100 bg-warning-50/20 my-2">
                            An email has been sent to your account. Click the link in the email to verify your account. 
                        </blockquote> 
                    )}
                </div>                     
            </ModalBody>

            <ModalFooter>
                <Button onClick={props.onCancel} color="danger">Cancel</Button>

                <Button 
                    startContent={<FontAwesomeIcon icon={faLock}/>}
                    color="primary"
                    onClick={startVerification}
                    isLoading={isLoading}
                    isDisabled={!props.isValidEmail}
                >
                    Send Verification Code
                </Button>
            </ModalFooter>
        </ModalContent>
    )
}