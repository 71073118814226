import "./Badges.css"
import { Button, Card, Chip, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Image, Link, Skeleton, Spacer } from "@nextui-org/react";
import { useEffect, useState } from "react";
import Face from "./Builder/Components/face/face";
import { resizeBadge, shuffle } from "../utils";
import { IBadgeTemplate } from "../Models/IBadgeTemplate";
import { color } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faHammer } from "@fortawesome/free-solid-svg-icons";
import BadgeList from "../Components/badge-list";

export function ProfessionalBadges() {
    return (
        <div>
            <BadgesHeader image="/professional.jpg" name="Professional" />

            <Spacer y={5} />

            <BadgeList type="professional" /> 

            <Spacer y={5} />

            <Footer />
        </div>
    )
}

export function EntertainmentBadges() {
    return (
        <div>
            <BadgesHeader image="/entertainment.jpg" name="Entertainment" />

            <Spacer y={5} />

            <BadgeList type="entertainment" /> 

            <Spacer y={5} />

            <Footer />
        </div>
    )
}

function BadgesHeader(props: {image: string, name: string}) {
    return (
        <div style={{position: 'relative'}}>
            <Image 
                shadow="md"
                radius="sm"
                width="100%"
                className="object-cover h-[30vh] blur-lg"
                isBlurred
                src={`/images${props.image}`}
                />

            <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000}}
                className="text-5xl text-white font-medium tracking-widest text-white decoration-warning decoration-2 underline underline-offset-8  p-5"    
            >
                {props.name}
            </div>

            <Spacer y={10} />

            <div className="text-center text-default-500 text-lg">
                Select a badge or <Link href="/build">Make Your Own</Link> 
            </div>
        </div>
    )   
}


function Footer() {
    return (
        <>
        <Spacer y={10} />

        <div className="text-center p-4 border-t bg-foreground-50/90">
            <div className="text-lg font-semibold">Looks like you've reached the end but don't give up, you can still make your own</div>
            <Spacer y={5} />
            <Button 
                color="warning" 
                size="lg" 
                as={Link} 
                href="/build"
                startContent={<FontAwesomeIcon icon={faHammer}/>}
                endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                className="font-semibold text-lg"
            >
                Make Your Own
            </Button>
            <Spacer y={3} />
        </div>
        </>
    )
}

