import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalContent, ModalHeader, ModalBody, Spacer, ModalFooter, Button, Input } from "@nextui-org/react";
import { useState, useEffect } from "react";

export default function CodeVerification(props: {
    email: string,
    onCancel: any,
    onVerified: any
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        setFailed(false);
    }, [code])

    const verify = async () => {
        try {
            setIsLoading(true);

            var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/verify-identity`, {
                method: 'POST',
                body: JSON.stringify({
                    email: props.email,
                    code
                })
            });
            
            if(!res.ok) throw new Error();

            props.onVerified();
        } catch {
            setFailed(true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <ModalContent>
            <ModalHeader>Verify Email</ModalHeader>
            <ModalBody>
                <div>
                    <blockquote className="text-center border px-4 py-3 rounded-xl [&>p]:m-0 border-warning-100 bg-warning-50/20 my-2">
                        A code has been sent to {props.email}
                    </blockquote> 

                    <Spacer y={3} />

                    <Input 
                        label="Code"
                        value={code}
                        onValueChange={setCode}
                        errorMessage={failed && "Invalid code"}
                        isInvalid={failed}
                    />
                </div>                     
            </ModalBody>

            <ModalFooter>
                <Button onClick={props.onCancel} color="danger">Cancel</Button>

                <Button 
                    startContent={<FontAwesomeIcon icon={faCheck}/>}
                    color="primary"
                    onClick={verify}
                    isLoading={isLoading}
                    isDisabled={code == ''}
                >
                    Verify
                </Button>
            </ModalFooter>
        </ModalContent>
    )
}