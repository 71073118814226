import { faHammer, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, Chip, Spacer, Card, Dropdown, DropdownTrigger, Button, DropdownMenu, DropdownSection, DropdownItem, Link } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { IBadgeTemplate } from "../Models/IBadgeTemplate";
import Face from "../Pages/Builder/Components/face/face";
import { resizeBadge } from "../utils";

export default function BadgeList(props: {type: string}) {
    const [loading, setLoading] = useState(true);
    const [allTemplates, setAllTemplates] = useState<IBadgeTemplate[]>([]);
    const [templates, setTemplates] = useState<IBadgeTemplate[]>([]);
    const [types, setTypes] = useState<(string | null)[]>([]);
    const [filterType, setFilterType] = useState<string | null>(null);
    const [colors, setColors] = useState<(string | null)[]>([]);
    const [filterColor, setFilterColor] = useState<string | null>(null);
    const [filterLandscape, setFilterLandscape] = useState<boolean | null>(null);
    const [filterLanyard, setFilterLanyard] = useState<boolean | null>(null);

    useEffect(() => {
        setLoading(true);

        fetch(`${process.env.PUBLIC_URL}/badge-templates/templates.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => json[props.type])
            //.then(shuffle)
            .then((templates: IBadgeTemplate[]) => {
                for(var template of templates) {
                    resizeBadge(template.badge);
                }
                return templates;
            })
            .then(setAllTemplates)
            .finally(() => setLoading(false));
    }, [props.type])

    useEffect(() => {
        setTypes(['All', ...new Set(allTemplates.flatMap(x => x.types))])
        setColors(['All', ...new Set(allTemplates.flatMap(x => x.colors).sort())])
    }, [allTemplates])

    useEffect(() => {
        let templates = [...allTemplates];

        if(filterType != null && filterType != 'All') templates = templates.filter(x => x.types.includes(filterType));
        if(filterColor != null && filterColor != 'All') templates = templates.filter(x => x.colors.includes(filterColor));
        if(filterLandscape != null) templates = templates.filter(x => x.badge.landscape == filterLandscape);
        if(filterLanyard != null) templates = templates.filter(x => x.badge.lanyard == filterLanyard);
 
        setTemplates(templates)
    }, [allTemplates, filterType, filterColor, filterLandscape, filterLanyard])

    const resetFilters = () => {
        setFilterType(null);
        setFilterColor(null);
        setFilterLandscape(null);
        setFilterLanyard(null);
    }
    
    return loading 
    ? (
        <div>
            <div className="filters">
                {[...Array(3)].map((e, i) => (
                    <Skeleton key={i} className="flex rounded-full">
                        <Chip size="lg">Test</Chip>
                    </Skeleton>
                ))}
            </div>
            <Spacer y={5} />
            <div className="badges-grid">
                {[...Array(9)].map((e, i) => (
                    <Card key={i} className="w-3/12 space-y-5 p-4" radius="lg">
                        <Skeleton className="rounded-lg">
                            <div className="h-24 rounded-lg bg-default-300"></div>
                        </Skeleton>
                        <div className="space-y-3">
                            <Skeleton className="w-3/5 rounded-lg">
                                <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-4/5 rounded-lg">
                                <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-2/5 rounded-lg">  
                                <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                            </Skeleton>
                        </div>
                    </Card>  
                ))}
            </div>
        </div>
    )
    : (
        <div>
            <div className="filters">
                <div>
                    <span className="text-tiny text-default-500">Type</span>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button fullWidth variant="bordered" size="lg">{filterType || 'All'}</Button>
                        </DropdownTrigger>
                        <DropdownMenu onAction={key => setFilterType(key.toString())}>
                            <DropdownSection title="Type" className="dropdown">
                                {types.map(type => (
                                    <DropdownItem className="text-lg" key={type || 'Other'}>{type || 'Other'}</DropdownItem>
                                ))}
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div>
                    <span className="text-tiny text-default-500">Orientation</span>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button fullWidth variant="bordered" size="lg">{filterLandscape ? "Landscape" : filterLandscape != null ? "Portrait" : 'All'}</Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                            <DropdownSection title="Orientation">
                                <DropdownItem onClick={() => setFilterLandscape(null)}>All</DropdownItem>
                                <DropdownItem onClick={() => setFilterLandscape(true)}>Landscape</DropdownItem>
                                <DropdownItem onClick={() => setFilterLandscape(false)}>Portrait</DropdownItem>
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div>
                    <span className="text-tiny text-default-500">Lanyard</span>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button fullWidth variant="bordered" size="lg">{filterLanyard ? 'Yes' : filterLanyard != null ? 'No' : 'All'}</Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                            <DropdownSection title="Lanyard">
                                <DropdownItem onClick={() => setFilterLanyard(null)}>All</DropdownItem>
                                <DropdownItem onClick={() => setFilterLanyard(true)}>Yes</DropdownItem>
                                <DropdownItem onClick={() => setFilterLanyard(false)}>No</DropdownItem>
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div>
                    <span className="text-tiny text-default-500">Color</span>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button fullWidth variant="bordered" size="lg">{filterColor || 'All'}</Button>
                        </DropdownTrigger>
                        <DropdownMenu onAction={key => setFilterColor(key?.toString())}>
                            <DropdownSection title="Color" className="dropdown">
                                {colors.map(color => (
                                    <DropdownItem key={color || 'Other'}>{color || 'Other'}</DropdownItem>
                                ))}
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            <Spacer y={2} />

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button color="danger" onClick={resetFilters}>Reset Filters</Button>
            </div>

            <Spacer y={5} />

            <div className="badges-grid">
                {templates.map((template, i) => (
                    <Card key={i}  isPressable as={Link} href={`/build?type=${props.type}&id=${template.id}`}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Face face={template.badge.front} landscape={template.badge.landscape} lanyard={template.badge.lanyard} scollable={true}/>
                        </div>

                        {/* <div className="mt-3 p-4" style={{display: 'flex', gap: '10px'}}>
                            {template.types.map((type, i) => (
                                <Chip size="sm" key={i}>{type}</Chip>
                            ))}
                        </div> */}
                    </Card>
                ))}
            </div>
        </div>
    )
}