import { useContext, useEffect, useState } from "react";
import { BadgeContext } from "../../../Context/badge-context";
import { Button, Tooltip } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { IBadge, IBadgeObject, IFace } from "../../../Models/IBadge";
import { cast, equal } from "../../../utils";

const states: IBadge[]= []

export default function Undo() {
    const {badge, setBadge} = useContext(BadgeContext);
    const [timeout, setStateTimeout] = useState<any>(null);

    const shallowCopy = (badge: IBadge) => {
        return cast<IBadge>(
            // shallow copy but keep ids so when resetting state on an undo, the edit context doesn't change
            {
                ...badge,
                front: cast<IFace>({
                    ...badge.front,
                    objects: badge.front.objects.map(x => cast<IBadgeObject>({...x}))
                }),
                back: cast<IFace>({
                    ...badge.back,
                    objects: badge.back.objects.map(x => cast<IBadgeObject>({...x}))
                })
            }
        )
    }

    const saveState = () => {
        if(states.length == 0|| !equal(states[states.length - 1], badge)) {
            states.push(shallowCopy(badge));

            if(states.length > 20) states.shift();
        }
    }

    const undo = () => {
        states.pop();

        if(states.length == 0) return;

        const state = states[states.length - 1];
        setBadge(shallowCopy(state));
    }

    useEffect(() => {
        if(timeout != null) clearTimeout(timeout);
        setStateTimeout(setTimeout(saveState, 250));
    }, [badge])

    return (
        <Tooltip content="Undo" color="success">
            <Button color="success"  onClick={undo} isIconOnly><FontAwesomeIcon icon={faUndo}/></Button>
        </Tooltip>
    )
}