import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faEraser, faFont, faMountainSun, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, Link, Modal, ModalBody, ModalContent, ModalHeader, Spacer, useDisclosure } from "@nextui-org/react";

export default function Help() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <>
            <Button 
                variant="flat"
                color="primary"
                startContent={<FontAwesomeIcon icon={faQuestionCircle}/>}
                onClick={onOpen}
                size="md"
            >
                Help
            </Button>


            <Modal isOpen={isOpen} onClose={onClose} placement="center">
                <ModalContent>
                    <ModalHeader>Help</ModalHeader>
                    <ModalBody>
                        <div className="text-center">
                            <Link href="mailto:support@build-a-badge.com">Contact Support</Link>
                        </div>

                        <Accordion selectionMode="multiple">
                            <AccordionItem title={<div className="font-bold uppercase">How To</div>}>
                                <Accordion variant="bordered">
                                    <AccordionItem title="Basics">
                                        <div>
                                            Click 

                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faEraser}/>}
                                                color="danger"
                                                isIconOnly
                                                className="mx-1"
                                                size="sm"
                                            >
                                            </Button>

                                            <span className="font-semibold">below</span> the badge to reset the current side of badge
                                        </div>
                                    </AccordionItem>
                                    <AccordionItem title="Edit Background">
                                        <div>
                                            Click
                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faMountainSun}/>}
                                                color="default"
                                                isIconOnly
                                                size="sm"
                                                className="mx-1"
                                            >
                                            </Button>
                                            for available background options
                                        </div>
                                        <div className="text-tiny text-default-500">Image and Color</div>
                                    </AccordionItem>
                                    <AccordionItem title="Add/Edit Images">
                                        <div>
                                            Click
                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faImage}/>}
                                                color="primary"
                                                isIconOnly
                                                className="mx-1"
                                                size="sm"
                                            >
                                            </Button>
                                            to add an image to the current side of the badge
                                        </div>

                                        <Spacer y={3} />

                                        Select the added image for available options 

                                        <div className="text-tiny text-default-500">Resizing, Aligning, Moving, and Rotating</div>
                                    </AccordionItem>

                                    <AccordionItem title="Add/Edit Text">
                                        <div>
                                            Click
                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faFont}/>}
                                                color="secondary"
                                                isIconOnly
                                                className="mx-1"
                                                size="sm"
                                            >
                                            </Button>
                                            to add text to the current side of the badge
                                        </div>

                                        <Spacer y={3} />
                                        Select the added text for available options 
                                        <div className="text-tiny text-default-500">Styling, Font, Aligning, Moving, and Rotating</div>
                                    </AccordionItem>
                                </Accordion>                    
                            </AccordionItem>
                            <AccordionItem title={<div className="font-bold uppercase">Badge Specs</div>}>
                                <div>
                                    Badges are the same size as a credit card 
                                    <div className="text-tiny text-default-500">3.375 inches wide by 2.125 inches high</div>
                                </div>

                                <Spacer y={2} />

                                <div>
                                    Badges do not have a magnetic strip.
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<div className="font-bold uppercase">Uses</div>}>
                                <div>
                                    Build A Badge should be used to create badges for work or entertainment.
                                    <br />
                                    <span className="text-default-500">Examples: McLovin, Employee Badge</span>

                                    <Spacer y={2} />

                                    It should <span className="font-bold">not</span> be used for official government identification or illicit content. 
                                    Doing so may result in the authorities being contacted. 
                                </div>
                            </AccordionItem>
                        </Accordion>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}