import { faAlignCenter, faAlignLeft, faAlignRight, faArrowDown, faArrowUp, faBold, faCheck, faFill, faFont, faItalic, faTextHeight, faUnderline } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Popover, PopoverContent, PopoverTrigger, Slider, Textarea, Tooltip } from "@nextui-org/react";
import { IFaceImage, IFaceText } from "../../../../Models/IBadge";
import { highlightValue } from "../../../../utils";
import { useEffect, useState } from "react";
import BorderMenu from "./border";
import LayerMenu from "./layer";
import ColorPicker from "./color-picker";
import PaddingMenu from "./padding";
import PositionMenu from "./position";
import SizeMenu from "./size";
import RotationMenu from "./rotation";

type FaceTextChanged = (faceText: IFaceText) => void;
export default function FaceTextMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IFaceText,
    onValueChanged: FaceTextChanged
}) {
    const [content, setContent] = useState(props.value.content);

    useEffect(() => {
        props.onValueChanged({...props.value, content})
    }, [content])

    useEffect(() => {
        setContent(props.value.content)
        document.getElementById('textarea')?.focus();
    }, [props.value.id])

    const toggleBold = () => props.onValueChanged({...props.value, bold: !props.value.bold})
    const toggleUnderline = () => props.onValueChanged({...props.value, underline: !props.value.underline})
    const toggleItalic = () => props.onValueChanged({...props.value, italic: !props.value.italic})
    const setColor = (color: string) => props.onValueChanged({...props.value, color})
    const setBackgroundColor = (backgroundColor: string) => props.onValueChanged({...props.value, backgroundColor})
    const setFontFamily = (fontFamily: string) => props.onValueChanged({...props.value, fontFamily})
    const setFontSize = (fontSize: number | undefined) => props.onValueChanged({...props.value, fontSize})
    const setLineHeight = (lineHeight: number) => props.onValueChanged({...props.value, lineHeight})
    const setLetterSpacing = (letterSpacing: number) => props.onValueChanged({...props.value, letterSpacing})

    return (
        <div style={{display: 'grid', gap: '25px'}}>
            <Textarea 
                id="textarea"
                autoFocus={true} 
                value={content}
                onFocus={highlightValue}
                onValueChange={setContent}
            />

            <div style={{display: 'flex', gap: '15px', flexWrap: 'wrap'}}>
                <ButtonGroup color={props.color}size={props.size}>
                    <Button isIconOnly onClick={toggleBold} variant={props.value.bold ? 'faded' : 'solid'}><FontAwesomeIcon icon={faBold} size="lg"/></Button>
                    <Button isIconOnly onClick={toggleItalic} variant={props.value.italic ? 'faded' : 'solid'}><FontAwesomeIcon icon={faItalic} size="lg"/></Button>
                    {/* <Button isIconOnly onClick={toggleUnderline} variant={props.value.underline ? 'faded' : 'solid'}><FontAwesomeIcon icon={faUnderline} size="lg"/></Button> */}
                </ButtonGroup>

                <TextAlignment size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceText)} />
                <LayerMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceText)} />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '8fr auto auto auto', gap: '15px', flexWrap: 'wrap'}}>
                <div>
                    <Dropdown size={props.size}>
                        <DropdownTrigger>
                            <Button size={props.size} fullWidth color={props.color} style={{fontFamily: props.value.fontFamily}}>{props.value.fontFamily || 'Default'}</Button>
                        </DropdownTrigger>
                        <DropdownMenu onAction={key => setFontFamily(key.toString())}>
                            <DropdownSection title="Font Family" className="dropdown">
                                {fontFamilies.map(fontFamily => (
                                    <DropdownItem 
                                        key={fontFamily} 
                                        value={fontFamily}
                                        endContent={props.value.fontFamily == fontFamily ? <FontAwesomeIcon icon={faCheck}/> : <></>}
                                        textValue={fontFamily}
                                    ><span className="text-xl" style={{fontFamily: fontFamily}}>{fontFamily}</span></DropdownItem>
                                ))}
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <ButtonGroup color={props.color} size={props.size}>
                    <ColorPicker tooltip="Font Color" size={props.size} color={props.color} icon={faFont} value={props.value.color} onValueChange={setColor}/>
                    <ColorPicker tooltip="Fill Color" size={props.size} color={props.color} icon={faFill} value={props.value.backgroundColor} onValueChange={setBackgroundColor} />
                </ButtonGroup>

                <div>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button size={props.size} color={props.color} isIconOnly>{props.value.fontSize || <FontAwesomeIcon icon={faTextHeight} size="lg"/>}</Button>
                        </DropdownTrigger>
                        <DropdownMenu onAction={key => key == 'default' ? setFontSize(undefined) : setFontSize(parseInt(key.toString()))}>
                            <DropdownSection title="Font Size" className="dropdown">
                                {fontSizes.map(fontSize => (
                                    <DropdownItem 
                                        key={fontSize || 'default'} 
                                        value={fontSize as any}
                                        endContent={props.value.fontSize == fontSize ? <FontAwesomeIcon icon={faCheck}/> : <></>}
                                    >{fontSize || 'Default'}</DropdownItem>
                                ))}
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <ButtonGroup color={props.color}size={props.size}>
                    <Tooltip content="Decrease Line Height">
                        <Button isIconOnly onClick={() => setLineHeight((props.value.lineHeight || 1) - .1)}><FontAwesomeIcon icon={faArrowDown}/></Button>
                    </Tooltip>
                    <Tooltip content="Increase Line Height">
                        <Button isIconOnly onClick={() => setLineHeight((props.value.lineHeight || 1) + .1)}><FontAwesomeIcon icon={faArrowUp}/></Button>
                    </Tooltip>
                </ButtonGroup>
            </div>

            <div>
                <p className="text-tiny text-default-500 mb-1">Letter Spacing</p>
                <Slider
                    minValue={0.1}
                    maxValue={100}
                    step={1} 
                    color={props.color}
                    size={props.size} 
                    value={props.value.letterSpacing}
                    onChange={v => setLetterSpacing(v as number)}  
                />
            </div>

            <Accordion selectionMode="multiple">
                <AccordionItem title="Border">
                    <BorderMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceText)} />
                    <div style={{marginTop: '15px'}}></div>
                    <PaddingMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceText)} />

                </AccordionItem>
                <AccordionItem title="Position">
                    <PositionMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceText)} />
                </AccordionItem>
                <AccordionItem title="Rotation">
                    <RotationMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceText)} />
                </AccordionItem>
                <AccordionItem title="Size">
                    <SizeMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceText)} />
                </AccordionItem>
            </Accordion>
        </div>
    )
}

function TextAlignment(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IFaceText,
    onValueChanged: FaceTextChanged
}) {
    const [open, setOpen] = useState(false);

    const setTextAlign = (textAlign: string) => props.onValueChanged({...props.value, textAlign})
    const toggleSpaceEvenly = () => props.onValueChanged({...props.value, spaceWordsEvenly: !props.value.spaceWordsEvenly})

    if(!open) return (
        <Tooltip content="Text Alignment">
            <Button size={props.size} isIconOnly color={props.color} onClick={() => setOpen(true)}><FontAwesomeIcon icon={faAlignCenter} size="lg"/></Button>
        </Tooltip>
    )

    return (
        <Popover isOpen={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <Button size={props.size} isIconOnly color={props.color}><FontAwesomeIcon icon={faAlignCenter} size="lg"/></Button>
            </PopoverTrigger>
            <PopoverContent>
                <p className="text-tiny text-default-500 mb-2">Text Alignment</p>
                <div style={{display: 'flex', gap: '15px'}}>
                    <ButtonGroup color={props.color} size={props.size}>
                        <Tooltip content="Left">
                            <Button isIconOnly onClick={() => setTextAlign('left')} variant={props.value.textAlign == 'left' ? 'faded' : 'solid'}><FontAwesomeIcon icon={faAlignLeft}/></Button>
                        </Tooltip>
                        <Tooltip content="Center">
                            <Button isIconOnly onClick={() => setTextAlign('center')} variant={props.value.textAlign == 'center' ? 'faded' : 'solid'}><FontAwesomeIcon icon={faAlignCenter}/></Button>
                        </Tooltip>
                        <Tooltip content="Right">
                            <Button isIconOnly onClick={() => setTextAlign('right')} variant={props.value.textAlign == 'right' ? 'faded' : 'solid'}><FontAwesomeIcon icon={faAlignRight}/></Button>
                        </Tooltip>
                        
                    </ButtonGroup>

                    <Tooltip content="Space words evenly">
                        <Button color={props.color} size={props.size} onClick={toggleSpaceEvenly} variant={props.value.spaceWordsEvenly ? 'faded' : 'solid'}>Space Evenly</Button>
                    </Tooltip>
                </div>
            </PopoverContent>
        </Popover>
    )
}


const fontSizes = [null, 8, 10, 12, 14, 16, 18, 20, 22, 26, 32, 40, 48, 58, 68, 80, 92, 106, 120, 140]
const fontFamilies = [
    'Arial',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Times New Roman',
    'Georgia',
    'Garamond',
    'Courier New',
    'Brush Script MT',
    'Monoton',
    'Roboto',
    'Funnel Display',
    'Alumni Sans Pinstripe',
    'Pacifico',
    'Meow Script',
    'Yellowtail',
    'Barriecito',
    'Rubik Moonrocks',
    'Butcherman',
    'Moirai One',
    'Lavishly Yours',
    'Pattaya',
    'Teko',
    'Obitron',
    'Goldman',
    'Turret Road'
].sort() 