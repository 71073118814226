import "./badge-editor.css"
import { useContext, useEffect } from "react";
import { OptionsContext } from "../../../Context/options-context";
import { BadgeContext } from "../../../Context/badge-context";
import { IFace } from "../../../Models/IBadge";
import { FaceContext } from "../../../Context/face-context";
import FaceEditor from "./face/face-editor";
import { Tab, Tabs } from "@nextui-org/react";
import { copyFace } from "../../../utils";
import EditContextMenu from "./menu/edit-context";
import Preview from "./preview";
import { EditContext } from "../../../Context/edit-context";

export default function BadgeEditor() {
    const {options} = useContext(OptionsContext);
    const {badge, setBadge} = useContext(BadgeContext);
    const {setEditContext} = useContext(EditContext);

    const setFrontFace = (face: IFace) => setBadge({...badge, front: face})
    const setBackFace = (face: IFace) => setBadge({...badge, back: face})

    useEffect(() => {
        if(options.linkSides) setBadge({...badge, back: badge.front})
        else setBadge({...badge, back: copyFace(badge.back)})
    }, [options.linkSides, badge.front]);

    return (
        <div id="badge-editor" onClick={() => setEditContext(null)}>
            <div>
                <div id="edit-menu-container" onClick={e => e.stopPropagation()}>
                    <EditContextMenu />
                </div>
            </div>

            <div>
                <Tabs classNames={{ base: 'face-tabs'}} size="lg" color="primary">
                    <Tab title="Front">
                        <FaceContext.Provider value={{face: {...badge.front}, setFace: setFrontFace}}>
                            <FaceEditor />
                        </FaceContext.Provider>
                    </Tab>
                    <Tab title="Back">
                        <FaceContext.Provider value={{face: {...badge.back}, setFace: setBackFace}}>
                            <FaceEditor />
                        </FaceContext.Provider>
                    </Tab>
                    <Tab title="Both">
                        <div id='multi-face'>
                            <FaceContext.Provider value={{face: {...badge.front}, setFace: setFrontFace}}>
                                <FaceEditor />
                            </FaceContext.Provider>

                            <FaceContext.Provider value={{face: {...badge.back}, setFace: setBackFace}}>
                                <FaceEditor />
                            </FaceContext.Provider>
                        </div>
                    </Tab>
                </Tabs>
            </div>

            <div className="d-big">
                <Preview />
            </div>
        </div>
    )
}